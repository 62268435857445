import { CbhIcon, ListItem, ListItemText } from "@clipboard-health/ui-components";
import { Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { type RecentColleague } from "@src/appV2/Agents/api/useGetRecentColleagues";
import pluralize from "pluralize";
import { useMemo, useState } from "react";

import { Button } from "../../components/Button";
import { type ShiftSlotCoalesced } from "../../Shift/Open/useGetShiftsSlotV2";
import { type WorkerPublicProfile } from "../hooks/useGetWorkerPublicProfile";
import { useIsWorkWithFriendsV2Enabled } from "../hooks/useIsWorkWithFriendsV2Enabled";
import AddFriendBottomSheet from "../modals/AddFriend/AddFriend";
import WorkingThisShiftWorker from "./WorkingThisShiftWorker";

interface WorkingThisShiftListProps {
  shiftSlots?: ShiftSlotCoalesced;
  friendsMap?: Map<string, WorkerPublicProfile>;
  recentColleaguesMap?: Map<string, RecentColleague>;
  outboundFriendRequestsSet?: Set<string>;
}

export function WorkingThisShiftListItem(props: WorkingThisShiftListProps) {
  const { shiftSlots, friendsMap, recentColleaguesMap, outboundFriendRequestsSet } = props;

  const addFriendModalState = useModalState();
  const [friendId, setFriendId] = useState<string | undefined>(undefined);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const availabilityText = useMemo(() => {
    const availableQualifications = Object.entries(shiftSlots?.availableQualifications ?? {});

    const friendsCount =
      shiftSlots?.shiftAssignments?.reduce((accumulator, shiftAssignment) => {
        if (friendsMap?.has(shiftAssignment.workerId)) {
          return accumulator + 1;
        }

        return accumulator;
      }, 0) ?? 0;

    let availabilityText = "";

    if (friendsCount > 0) {
      availabilityText += `${friendsCount} ${pluralize("friend", friendsCount)} working.`;
      availabilityText += " ";
    }

    if (availableQualifications.length > 0) {
      availabilityText += `Looking for ${availableQualifications.reduce(
        (qualificationText, [qualification, count], index) => {
          let result = qualificationText;
          if (index === availableQualifications.length - 1 && availableQualifications.length > 1) {
            result += " and ";
          }

          let pluralizedQualification = pluralize(qualification, count);

          if (count > 1 && pluralizedQualification.endsWith("S")) {
            pluralizedQualification = `${pluralizedQualification.slice(0, -1)}s`;
          }

          result +=
            index === 0
              ? `${count} more ${pluralizedQualification}`
              : `${count} ${pluralizedQualification}`;

          if (index < availableQualifications.length - 2) {
            result += ", ";
          }

          return result;
        },
        ""
      )}`;
    }

    return availabilityText ?? undefined;
  }, [shiftSlots, friendsMap]);

  const isWorkWithFriendsV2Enabled = useIsWorkWithFriendsV2Enabled();
  if (!isWorkWithFriendsV2Enabled) {
    return null;
  }

  return (
    <ListItem>
      <ListItemText
        disableTypography
        primary={
          <Stack direction="row" gap={2} width="100%">
            <Stack width="2rem" flexShrink={0}>
              <CbhIcon type="user-filled" />
            </Stack>
            <Stack flex={1} minWidth={0}>
              <Text
                variant="h5"
                sx={(theme) => ({
                  color: theme.palette.text.primary,
                })}
                mb={2}
              >
                Working this shift
              </Text>
              <Stack>
                {isDefined(availabilityText) && (
                  <Text
                    variant="body2"
                    sx={(theme) => ({
                      color: theme.palette.text.secondary,
                    })}
                  >
                    {availabilityText}
                  </Text>
                )}
                {isDefined(shiftSlots?.shiftAssignments) &&
                  shiftSlots?.shiftAssignments.length > 0 && (
                    <Stack sx={{ mt: 6 }}>
                      {shiftSlots?.shiftAssignments.map((shiftAssignment) => (
                        <Stack key={shiftAssignment.workerId} mb={6}>
                          <WorkingThisShiftWorker
                            shiftAssignment={shiftAssignment}
                            actions={
                              isDefined(friendsMap) && !friendsMap.has(shiftAssignment.workerId) ? (
                                <Button
                                  size="small"
                                  disabled={
                                    isDefined(outboundFriendRequestsSet) &&
                                    outboundFriendRequestsSet.has(shiftAssignment.workerId)
                                  }
                                  onClick={() => {
                                    setFriendId(shiftAssignment.workerId);
                                    addFriendModalState.openModal();
                                  }}
                                >
                                  {isDefined(outboundFriendRequestsSet) &&
                                  outboundFriendRequestsSet.has(shiftAssignment.workerId)
                                    ? "Pending"
                                    : "Add"}
                                </Button>
                              ) : undefined
                            }
                            friendsMap={friendsMap}
                            recentColleaguesMap={recentColleaguesMap}
                          />
                        </Stack>
                      ))}
                    </Stack>
                  )}
              </Stack>
            </Stack>
          </Stack>
        }
      />

      {isDefined(friendId) && (
        <AddFriendBottomSheet
          modalState={addFriendModalState}
          friendId={friendId}
          onClose={() => {
            addFriendModalState.closeModal();
            setFriendId(undefined);
          }}
        />
      )}
    </ListItem>
  );
}
