import { Avatar } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { type AvatarSize } from "@clipboard-health/ui-theme";
import { Stack, type SxProps, type Theme } from "@mui/material";

import { type WorkerPublicProfile } from "../hooks/useGetWorkerPublicProfile";

interface Props {
  friend: WorkerPublicProfile;
  size?: AvatarSize;
  sx?: SxProps<Theme>;
}

export function ProfileImage(props: Props) {
  const { friend, size = "small", sx } = props;

  const colorOptions = ["primary", "secondary", "tertiary", "quaternary", "septenary"] as const;

  return (
    <Stack borderRadius="100%" sx={{ ...sx }} alignItems="center" justifyContent="center">
      <Avatar
        size={size === "small" ? "small" : "large"}
        src={friend.attributes.profileImageUrl}
        alt="Profile"
        color={colorOptions[Number.parseInt(friend.id, 10) % colorOptions.length]}
      >
        <Text variant={size === "small" ? "body1" : "h3"}>
          {`${friend.attributes.firstName.charAt(0)}${friend.attributes.lastName.charAt(0)}`}
        </Text>
      </Avatar>
    </Stack>
  );
}
