import { Avatar } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

import { type WorkersForOverlappingShiftSlots } from "../hooks/useGetWorkersForOverlappingShiftSlots";
import { useIsWorkWithFriendsV2Enabled } from "../hooks/useIsWorkWithFriendsV2Enabled";
import { ProfileImage } from "./ProfileImage";

interface Props {
  overlappingShiftWorkers: WorkersForOverlappingShiftSlots;
  profileImageSizeInPx?: number;
  maxVisibleImages?: number;
}

export function OverlappingShiftWorkersAvatars(props: Props) {
  const { overlappingShiftWorkers, profileImageSizeInPx = 32, maxVisibleImages = 2 } = props;

  const isWorkWithFriendsV2Enabled = useIsWorkWithFriendsV2Enabled();

  if (!isWorkWithFriendsV2Enabled) {
    return null;
  }

  // display friends first, then recently worked together and then everybody else
  const sortedWorkers = [
    ...overlappingShiftWorkers.friends,
    ...overlappingShiftWorkers.recentColleagues,
    ...overlappingShiftWorkers.otherWorkers,
  ];

  const visibleWorkers = sortedWorkers.slice(0, maxVisibleImages);

  return (
    <Stack flexDirection="row">
      {visibleWorkers.map((worker, index) => (
        <ProfileImage
          key={worker.id}
          sx={{
            // don't add margin to the last image
            marginRight: index === visibleWorkers.length - 1 ? 0 : -3,
            border: "1px solid white",
            width: profileImageSizeInPx,
            height: profileImageSizeInPx,
          }}
          friend={worker}
        />
      ))}
      {sortedWorkers.length > maxVisibleImages && (
        <Stack
          sx={{
            alignItems: "center",
            justifyContent: "center",
            marginLeft: -3,
          }}
        >
          <Avatar size="small">
            <Text variant="body2" sx={{ color: "text.secondary", fontWeight: "bold" }}>
              +{sortedWorkers.length - maxVisibleImages}
            </Text>
          </Avatar>
        </Stack>
      )}
    </Stack>
  );
}
