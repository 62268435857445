import { Avatar } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { type RecentColleague } from "@src/appV2/Agents/api/useGetRecentColleagues";
import { uniqBy } from "lodash";

import { type WorkerPublicProfile } from "../hooks/useGetWorkerPublicProfile";
import { useIsWorkWithFriendsV2Enabled } from "../hooks/useIsWorkWithFriendsV2Enabled";
import { ProfileImage } from "./ProfileImage";

interface Props {
  workers: WorkerPublicProfile[];
  friendsMap?: Map<string, WorkerPublicProfile>;
  recentColleaguesMap?: Map<string, RecentColleague>;
}

export function WorkTogetherStack(props: Props) {
  const { workers, friendsMap, recentColleaguesMap } = props;

  const isWorkWithFriendsV2Enabled = useIsWorkWithFriendsV2Enabled();

  if (!isWorkWithFriendsV2Enabled) {
    return null;
  }

  // display friends first, then recently worked together and then everybody else
  const friends = workers.filter((worker) => friendsMap?.has(worker.id));
  const recentlyWorkedTogether = workers.filter((worker) => recentColleaguesMap?.has(worker.id));
  const others = workers.filter(
    (worker) => !friendsMap?.has(worker.id) && !recentColleaguesMap?.has(worker.id)
  );
  const sortedWorkers = uniqBy([...friends, ...recentlyWorkedTogether, ...others], "id");

  return (
    <Stack flex={1} justifyContent="flex-end" flexDirection="row">
      {sortedWorkers.slice(0, 2).map((worker) => (
        <ProfileImage
          key={worker.id}
          sx={{ marginRight: -3, border: "1px solid white" }}
          size="small"
          friend={worker}
        />
      ))}
      {sortedWorkers.length > 2 && (
        <Stack sx={{ marginRight: -3 }}>
          <Avatar size="small">
            <Text variant="body2" sx={{ color: "text.secondary", fontWeight: "bold" }}>
              +{sortedWorkers.length - 2}
            </Text>
          </Avatar>
        </Stack>
      )}
    </Stack>
  );
}
