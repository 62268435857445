import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { type RecentColleague } from "@src/appV2/Agents/api/useGetRecentColleagues";
import { type ShiftAssignment } from "@src/appV2/redesign/Shift/Open/useGetShiftsSlotV2";
import { type ReactNode, useMemo } from "react";

import { type WorkerPublicProfile } from "../hooks/useGetWorkerPublicProfile";
import { ProfileImage } from "./ProfileImage";

interface Props {
  shiftAssignment: ShiftAssignment;
  actions: ReactNode;
  friendsMap?: Map<string, WorkerPublicProfile>;
  recentColleaguesMap?: Map<string, RecentColleague>;
}

export default function WorkingThisShiftWorker(props: Props) {
  const { shiftAssignment, actions, friendsMap, recentColleaguesMap } = props;
  const { worker, workingAgentReq } = shiftAssignment;

  const { title, subtitle } = useMemo(() => {
    let title = `${worker.attributes.firstName} ${worker.attributes.lastName}`;
    let subtitle = workingAgentReq;

    if (isDefined(friendsMap) && friendsMap.has(worker.id)) {
      title += `, ${workingAgentReq}`;
      subtitle = "Your Friend";
    } else if (isDefined(recentColleaguesMap) && recentColleaguesMap.has(worker.id)) {
      title += `, ${workingAgentReq}`;
      subtitle = "You recently worked the same shift";
    }

    return { title, subtitle };
  }, [worker, workingAgentReq, friendsMap, recentColleaguesMap]);

  return (
    <Stack direction="row">
      <Stack flex={1} flexDirection="row" alignItems="center" minWidth={0}>
        <Stack mr={4} flexShrink={0}>
          <ProfileImage friend={worker} />
        </Stack>
        <Stack minWidth={0} flex={1}>
          <Text
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {title}
          </Text>
          <Text
            variant="body2"
            sx={(theme) => ({
              color: theme.palette.text.secondary,
            })}
          >
            {subtitle}
          </Text>
        </Stack>
      </Stack>
      <Stack flexDirection="row" alignItems="center" gap={2} flexShrink={0} ml={2}>
        {actions}
      </Stack>
    </Stack>
  );
}
