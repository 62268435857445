import {
  CbhIcon,
  LoadingSpinner,
  PageHeaderWrapper,
  Tab,
  Tabs,
} from "@clipboard-health/ui-components";
import { Title } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Button } from "../components/Button";
import { IconButton } from "../components/IconButton";
import {
  useGetFriendsList,
  useGetInboundFriendsRequests,
  useGetOutboundFriendsRequests,
} from "./hooks";
import { type FriendIdParams, VISIBILITY_SETTINGS_MODAL_PATH } from "./paths";
import TabFriends from "./tabs/TabFriends";
import TabInvites from "./tabs/TabInvites";
import TabPending from "./tabs/TabPending";
import WorkWithFriendsContext, { type SelectedTab } from "./WorkWithFriendsContext";

export function WorkWithFriendsPage() {
  const history = useHistory();

  const { setActiveFriend, setSelectedTab, selectedTab } = useContext(WorkWithFriendsContext);
  const { data: { data: friends } = { data: [] }, isLoading: isLoadingFriends } =
    useGetFriendsList();
  const { data: { data: inboundFriends } = { data: [] } } = useGetInboundFriendsRequests();
  const { data: { data: outboundFriends } = { data: [] } } = useGetOutboundFriendsRequests();

  useEffect(() => {
    if (inboundFriends.length > 0) {
      setSelectedTab("invitations");
    }
  }, [inboundFriends, setSelectedTab]);

  const { friendId } = useParams<FriendIdParams>();
  useEffect(() => {
    if (isDefined(friendId) && isDefined(friends)) {
      const friendFromUrlParameter = friends.find((friend) => friend.id === friendId);
      if (isDefined(friendFromUrlParameter)) {
        setActiveFriend(friendFromUrlParameter);
      } else {
        history.replace("/home-v2/account/friends");
      }
    }
  }, [friendId, friends, history, setActiveFriend]);

  return (
    <Stack
      sx={(theme) => ({
        flex: 1,
        backgroundColor: theme.palette.background.primary,
        position: "relative",
      })}
    >
      <PageHeaderWrapper
        sx={(theme) => ({
          backgroundColor: theme.palette.background.tertiary,
        })}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          position="relative"
          height={(theme) => theme.spacing(10)}
        >
          <Stack position="absolute" left={(theme) => theme.spacing(3)}>
            <IconButton
              iconType="arrow-left"
              variant="outlined"
              onClick={() => {
                history.goBack();
              }}
            />
          </Stack>
          <Stack position="absolute" right={(theme) => theme.spacing(3)}>
            <IconButton
              iconType="ellipsis"
              variant="outlined"
              onClick={() => {
                history.replace(VISIBILITY_SETTINGS_MODAL_PATH);
              }}
            />
          </Stack>
        </Stack>
        <Stack px={6} pt={10} pb={6}>
          <Title semibold component="h1" variant="h3">
            Work with friends
          </Title>
        </Stack>
      </PageHeaderWrapper>
      <Stack flex={1} m={6}>
        <Stack alignItems="center">
          <Tabs
            value={selectedTab}
            variant="pill"
            onChange={(_, value: SelectedTab) => {
              setSelectedTab(value);
            }}
          >
            <Tab variant="pill" label="Friends" value="friends" />
            <Tab
              variant="pill"
              label="Pending"
              value="pending"
              badgeCount={outboundFriends.length > 0 ? outboundFriends.length : undefined}
            />
            <Tab
              variant="pill"
              label="Invitations"
              value="invitations"
              badgeCount={inboundFriends.length > 0 ? inboundFriends.length : undefined}
            />
          </Tabs>
        </Stack>
        <Stack
          sx={{
            flex: 1,
            overflow: "auto",
            minHeight: 0,
          }}
        >
          {selectedTab === "friends" && (
            <Stack flex={1} p={3}>
              {isLoadingFriends ? (
                <Stack flex={1} justifyContent="center" alignItems="center">
                  <LoadingSpinner size="xlarge" />
                </Stack>
              ) : (
                <TabFriends friends={friends} />
              )}
            </Stack>
          )}
          {selectedTab === "pending" && (
            <Stack flex={1} p={3}>
              {isLoadingFriends ? (
                <Stack flex={1} justifyContent="center" alignItems="center">
                  <LoadingSpinner size="xlarge" />
                </Stack>
              ) : (
                <TabPending friends={outboundFriends} />
              )}
            </Stack>
          )}
          {selectedTab === "invitations" && (
            <Stack flex={1} p={3}>
              {isLoadingFriends ? (
                <Stack flex={1} justifyContent="center" alignItems="center">
                  <LoadingSpinner size="xlarge" />
                </Stack>
              ) : (
                <TabInvites friends={inboundFriends} />
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack
        sx={(theme) => ({
          p: 6,
          pb: 8,
          borderTop: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.background.tertiary,
        })}
      >
        <Button
          size="large"
          onClick={() => {
            history.replace("/home-v2/account/friends/invite");
          }}
        >
          <CbhIcon sx={{ mr: 4 }} type="invite" />
          Invite Friends
        </Button>
      </Stack>
    </Stack>
  );
}
